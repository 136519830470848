<template>
    <main style="flex-direction: column;">
        <div class="no-modal">
            <div class="newformat">
                <div class="col-md-4">
                    <div class="container-busqueda">
                        <div class="cuadro-busqueda-postulantes col-8">
                            <form class="input-group" @submit.prevent="viewInfo()">
                                <input type="search" class="form-control" v-model="searchInput2"
                                    placeholder="Buscar por nombre..." />
                            </form>
                        </div>
                        <div class="btn-group">
                            <button class="btn border-secondary btn-m dropdown-toggle" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false" title="Ordenar Por">
                                <box-icon name='filter-alt'></box-icon>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="selection-a dropdown-item" v-on:click="sortEdad(dataPostulate)">
                                        Edad (Menor al Mayor)
                                    </a>
                                </li>
                                <li>
                                    <a class="selection-a dropdown-item" v-on:click="sortSalario(dataPostulate)">
                                        Salario Deseado (Menor al Mayor)
                                    </a>
                                </li>
                            </ul>
                        </div>
                        &nbsp;
                        <div class="btn-group">
                            <button class="btn border-secondary btn-m dropdown-toggle" title="Filtrar por" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <box-icon name='filter'></box-icon>
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="selection-a dropdown-item" v-on:click="sortStatusApto()">Apto</a>
                                </li>
                                <li><a class="selection-a dropdown-item" v-on:click="sortStatusNoApto()">No Apto</a>
                                </li>
                                <li><a class="selection-a dropdown-item" v-on:click="sortStatusConsiderar()">
                                        A Considerar</a>
                                </li>
                                <li><a class="selection-a dropdown-item" v-on:click="sortStatus()">Todos los Estatus</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Tarjetas Postulante  -->
                    <div class="cards-vacantes">
                        <div class="d-flex flex-column" v-for="(item, i) in dataPostulate" :key="i + 1"
                            v-show="filterP(item)">
                            <div class="J-talento-item" style="cursor:pointer;" v-on:click="viewPostulates(item)">
                                <div class="d-flex" style="justify-content: left; align-items: left;">
                                    <div class="d-flex flex-column">
                                        <div v-if="item.status" style="font-family: Poppins;">
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-if="item.status === 'considerar'" class="text-warning">
                                                A Considerar
                                            </span>
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-else-if="item.status === 'apto'" class="text-success">
                                                Apto
                                            </span>
                                            <span style="font-size: 12px; font-weight: bold;"
                                                v-else-if="item.status === 'no apto'" class="text-danger">
                                                No Apto
                                            </span>
                                            <div v-else-if="item.status === 'contratado'">
                                                <span v-if="typeVacante === 'Egresado'" style="font-size: 12px; font-weight: bold;" class="text-primary">
                                                    Contratado
                                                 </span>
                                                 <span v-else style="font-size: 12px; font-weight: bold;" class="text-primary">
                                                    Becario Aceptado
                                                 </span>
                                            </div>
                                            <span
                                                style="display: flex; font-size: 12px; text-transform: capitalize; color: gray;">
                                                Postulado el: {{ moment(item.createdAt).format("DD MMMM") }}
                                            </span>
                                        </div>
                                        <div class="d-flex flex-row" style="justify-content: left; align-items: left;">
                                            <span class="talento-text-title">
                                                {{ item.student.name }}
                                                {{ item.student.lastname }}
                                                {{ item.student.secondlastname }}
                                            </span>
                                        </div>
                                        <div
                                            style="display: flex; flex-direction: row; justify-content: left; font-family: 'Poppins';">
                                            <div v-show="item.student.status === 'exuniversitario'">
                                                <span style="font-size: 12px; text-transform: capitalize; color: gray;"
                                                    v-if="item.student.salarioAproximado">
                                                    ${{ item.student.salarioAproximado }}\
                                                </span>
                                            </div>
                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <box-icon name='location-plus' type='solid' ></box-icon>
                                                &nbsp;
                                                <div v-if="item.student.municipaly">
                                                    <span
                                                        style="font-size: 12px; color: gray; text-transform: uppercase;">
                                                        {{ item.student.municipaly }}, {{ item.student.country }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Información General -->
                <div class="cont-data-postulantes col-md-8 flex column" v-show="show != true">
                    <!-- Información rápida -->
                    <div style="display: flex; background-color: #f5f5f5; padding: 2%; align-items: center;">
                        <div style="width: 10%; margin: 10px;">
                            <img v-if="informationUser.photo" class="rounded-circle" :src="informationUser.photo.link"
                                alt="Imagen de usuario"
                                style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                            <img v-else class="rounded-circle" src="/images/profile.jpg" alt="Imagen de usuario"
                                style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                        </div>
                        <div style="display: flex; flex-direction: column; width: 90%;">
                            <div style="display: flex;  justify-content: left; align-items: center;">
                                <span
                                    style="font-size: 18px; font-weight: bold; font-family: Poppins; text-transform: uppercase;">
                                    {{ informationStudent.name }}
                                    {{ informationStudent.lastname }}
                                    {{ informationStudent.secondlastname }}
                                </span>
                            </div>
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div style="flex: 2;" v-show="informationStudent.status === 'preuniversitario'">
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        Estudiante de Bachillerato
                                    </span>
                                    <small> / </small>
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        {{ getEdad(informationStudent.date) }} años
                                    </span>
                                </div>
                                <div style="flex: 1;" v-show="informationStudent.status === 'universitario'">
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        Estudiante Universitario
                                    </span>
                                    <small> / </small>
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        {{ getEdad(informationStudent.date) }} años
                                    </span>
                                </div>
                                <div style="flex: 1;" v-show="informationStudent.status === 'exuniversitario'">
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        Profesionista Universitario
                                    </span>
                                    <small> / </small>
                                    <span style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                        {{ getEdad(informationStudent.date) }} años
                                    </span>
                                </div>
                                <div style="flex: 1; display:flex; justify-content: flex-end;">
                                    <div style="display:flex; justify-content: center; align-items: center;">
                                        <div v-if="informationStudent.linkedin">
                                            <a class="J-btn auto" title="LinkedIn" target="_blank"
                                                v-on:click="openLinkedin(informationStudent.linkedin)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" style="fill: #0e76a8;">
                                                    <circle cx="4.983" cy="5.009" r="2.188"></circle>
                                                    <path
                                                        d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z">
                                                    </path>
                                                </svg>

                                            </a>
                                        </div>
                                    </div>
                                    <div style="display:flex; justify-content: center; align-items: center;"
                                        v-if="informationStudent.filecv">
                                        <a class="J-btn auto" title="CV" target="_blank"
                                            :href="informationStudent.filecv.link">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" style="fill: red;">
                                                <path
                                                    d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z">
                                                </path>
                                                <path
                                                    d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z">
                                                </path>
                                            </svg>

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Nueva navegación -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                                aria-selected="true">Datos
                                Personales</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                data-bs-target="#profile-tab-pane" type="button" role="tab"
                                aria-controls="profile-tab-pane" aria-selected="false">Información
                                Escolar</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                data-bs-target="#contact-tab-pane" type="button" role="tab"
                                aria-controls="contact-tab-pane" aria-selected="false">Idiomas</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="disabled-tab" data-bs-toggle="tab"
                                data-bs-target="#disabled-tab-pane" type="button" role="tab"
                                aria-controls="disabled-tab-pane" aria-selected="false">Hobbies</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="experiencia-tab" data-bs-toggle="tab"
                                data-bs-target="#experiencia-tab-pane" type="button" role="tab"
                                aria-controls="experiencia-tab-pane" aria-selected="false">Experiencia</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <!-- Información Personal -->
                        <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel"
                            aria-labelledby="home-tab" tabindex="0">
                            <div class="container-postulante flex" style="padding: 2rem;">
                                <div style="display: flex; justify-content: flex-start; align-items: center;">
                                    <div class="d-flex flex-row mt-2">
                                        <div class="row">
                                            <div class="text-justify m-2">
                                                <b>Nombre:</b>
                                                {{ informationStudent.name }}
                                                {{ informationStudent.lastname }}
                                                {{ informationStudent.secondlastname }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2" v-if="informationStudent.date">
                                                <b>Edad: </b>{{ getEdad(informationStudent.date) }} años
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2">
                                                <b>Email:</b> {{ informationStudent.email }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2">
                                                <b>Celular:</b> {{ informationStudent.phone }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: flex-start; align-items: center;">

                                    <div class="row">
                                        <div class="text-justify m-2">
                                            <b>Fecha de Nacimiento:</b> {{
                                moment(informationStudent.date).format('DD/MM/YYYY')
                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="text-justify m-2">
                                            <b>Género:</b> {{ informationStudent.gender }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="text-justify m-2">
                                            <b>Pais:</b> {{ informationStudent.country }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="text-justify m-2">
                                            <b>Estado:</b> {{ informationStudent.estadoRepublica }}
                                        </div>
                                    </div>

                                </div>
                                <div style="display: flex; justify-content: flex-start; align-items: center;">
                                    <div class=" row">
                                        <div class="text-justify m-2">
                                            <b>Municipio/Delegación:</b> {{ informationStudent.municipaly }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="text-justify m-2">
                                            <b>Colonia:</b> {{ informationStudent.delegation }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Información Escolar -->
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                            tabindex="0">
                            <div class="container-postulante flex mt-3" style="padding: 2rem;">
                                <div style="display: flex; flex-direction: column;">
                                    <div style="display: flex; justify-content: left; align-items: center;"
                                        v-show="informationStudent.status != 'exuniversitario'">
                                        <div class="row">
                                            <div class="text-justify m-2"><b>Escuela:</b> {{ informationStudent.school
                                                }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.status != 'exuniversitario'">
                                            <div class="text-justify m-2"><b>Plantel:</b> {{ informationStudent.plantel
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: left; align-items: center;"
                                    v-show="informationStudent.status != 'exuniversitario'">
                                    <div class="row">
                                        <div class="text-justify m-2"><b>Carrera:</b> {{ informationStudent.career }}
                                        </div>
                                    </div>
                                    <div class="row" v-show="informationStudent.status == 'preuniversitario'">
                                        <div class="text-justify m-2"><b>Tipo de Bachillerato:</b>
                                            {{ informationStudent.typePreparatory }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="text-justify m-2"><b>Promedio:</b>
                                            {{ informationStudent.promedio }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="informationStudent.status == 'universitario'"
                                    style="display: flex; flex-direction: row;">
                                    <div class="row">
                                        <!-- Años cursados -->
                                        <div class="text-justify m-2"
                                            v-if="informationStudent.yearActual == 'MedioAño'">
                                            <b>Años
                                                Cursados: </b> Medio Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'UnAño'">
                                            <b>Años
                                                Cursados: </b> 1 Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'AñoyMedio'">
                                            <b>Años
                                                Cursados: </b> 1 Año y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'DosAños'">
                                            <b>Años
                                                Cursados: </b> 2 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'DosAñosyMedio'">
                                            <b>Años Cursados: </b> 2 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Tres Años'">
                                            <b>Años
                                                Cursados: </b> 3 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Tres Años y Medio'">
                                            <b>Años Cursados: </b> 3 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cuatro'">
                                            <b>Años
                                                Cursados: </b> 4 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cuatro Años y Medio'"><b>Años
                                                Cursados:
                                            </b> 4
                                            Años y medio </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cinco'">
                                            <b>Años
                                                Cursados: </b> 5 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'CincoAñosyMedio'">
                                            <b>Años Cursados: </b> 5 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'SeisAños'">
                                            <b>Años
                                                Cursados: </b> 6 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'SeisAñosyMedio'">
                                            <b>Años Cursados: </b> 6 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'SieteAños'">
                                            <b>Años
                                                Cursados: </b> 7 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'SieteAñosyMedio'">
                                            <b>Años Cursados: </b> 7 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == '+OchoAños'">
                                            <b>Años Cursados: </b> Más de 8 años
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- Duración de la carrera -->
                                        <div class="text-justify m-2" v-if="informationStudent.yearTotal == 'MedioAño'">
                                            <b>Duración de la carrera: </b> Medio Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'UnAño'">
                                            <b>Duración de la carrera: </b> 1 Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'AñoyMedio'">
                                            <b>Duración de la carrera: </b> 1 Año y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'DosAños'">
                                            <b>Duración
                                                de la carrera: </b> 2 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'DosAñosyMedio'">
                                            <b>Duración de la carrera: </b> 2 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Tres Años'">
                                            <b>Duración de la carrera: </b> 3 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Tres Años y Medio'">
                                            <b>Duración de la carrera: </b> 3 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cuatro'">
                                            <b>Duración
                                                de la carrera: </b> 4 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cuatro Años y Medio'">
                                            <b>Duración de la carrera: </b> 4 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cinco'">
                                            <b>Duración de
                                                la carrera: </b> 5 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'CincoAñosyMedio'">
                                            <b>Duración de la carrera: </b> 5 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SeisAños'">
                                            <b>Duración
                                                de la carrera: </b> 6 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SeisAñosyMedio'">
                                            <b>Duración de la carrera: </b> 6 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SieteAños'">
                                            <b>Duración de la carrera: </b> 7 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SieteAñosyMedio'">
                                            <b>Duración de la carrera: </b> 7 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == '+OchoAños'">
                                            <b>Duración de la carrera: </b> Más de 8 años
                                        </div>
                                    </div>
                                </div>
                                <div v-if="informationStudent.status == 'preuniversitario'"
                                    style="display: flex; flex-direction: row;">
                                    <div class="row">
                                        <!-- Años cursados -->
                                        <div class="text-justify m-2"
                                            v-if="informationStudent.yearActual == 'Medio Año'">
                                            <b>Años Cursados:</b> Medio Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Un Año'">
                                            <b>Años Cursados: </b> 1 Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Año y Medio'">
                                            <b>Años Cursados:</b> 1 Año y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Dos Años'">
                                            <b>Años
                                                Cursados:</b> 2 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Dos Años y Medio'">
                                            <b>Años Cursados:</b> 2 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Tres Años'">
                                            <b>Años
                                                Cursados:</b> 3 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Tres Años y Medio'">
                                            <b>Años Cursados:</b> 3 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cuatro'">
                                            <b>Años
                                                Cursados:</b> 4 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cuatro Años y Medio'"><b>Años
                                                Cursados:
                                            </b> 4
                                            Años y medio </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cinco'">
                                            <b>Años
                                                Cursados: </b> 5 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Cinco Años y Medio'">
                                            <b>Años Cursados: </b> 5 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Seis Años'">
                                            <b>Años
                                                Cursados: </b> 6 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Seis Años y Medio'">
                                            <b>Años Cursados: </b> 6 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Siete Años'">
                                            <b>Años
                                                Cursados: </b> 7 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == 'Siete Años y Medio'">
                                            <b>Años Cursados: </b> 7 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearActual == '+Ocho Años'">
                                            <b>Años
                                                Cursados: </b> Más de 8 años
                                        </div>

                                    </div>
                                    <div class="row">
                                        <!-- Duración de la carrera -->
                                        <div class="text-justify m-2" v-if="informationStudent.yearTotal == 'MedioAño'">
                                            <b>Duración
                                                de
                                                bachillerato: </b> Medio Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'UnAño'">
                                            <b>Duración de
                                                bachillerato: </b> 1 Año
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'AñoyMedio'">
                                            <b>Duración de bachillerato: </b> 1 Año y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'DosAños'">
                                            <b>Duración
                                                de bachillerato: </b> 2 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'DosAñosyMedio'">
                                            <b>Duración de bachillerato: </b> 2 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Tres Años'">
                                            <b>Duración de bachillerato: </b> 3 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Tres Años y Medio'">
                                            <b>Duración de bachillerato: </b> 3 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cuatro'">
                                            <b>Duración
                                                de bachillerato: </b> 4 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cuatro Años y Medio'">
                                            <b>Duración de bachillerato: </b> 4 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'Cinco'">
                                            <b>Duración de
                                                bachillerato: </b> 5 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'CincoAñosyMedio'">
                                            <b>Duración de bachillerato: </b> 5 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SeisAños'">
                                            <b>Duración
                                                de bachillerato: </b> 6 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SeisAñosyMedio'">
                                            <b>Duración de bachillerato: </b> 6 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SieteAños'">
                                            <b>Duración de bachillerato: </b> 7 Años
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == 'SieteAñosyMedio'">
                                            <b>Duración de bachillerato: </b> 7 Años y medio
                                        </div>
                                        <div class="text-justify m-2"
                                            v-else-if="informationStudent.yearTotal == '+OchoAños'">
                                            <b>Duración de bachillerato: </b> Más de 8 años
                                        </div>
                                    </div>
                                </div>
                                <!-- Formulario Ex-Universitario-->
                                <div v-if="informationStudent.status == 'exuniversitario'">
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <div class="text-justify m-2" v-if="informationStudent.schoolLic">
                                                <b>Escuela de Licenciatura: </b> {{ informationStudent.schoolLic }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2" v-if="informationStudent.careerLic">
                                                <b>Nombre de la carrera: </b> {{ informationStudent.careerLic }}
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <div class="text-justify m-2" v-if="informationStudent.startDateLic">
                                                <b>Inicio
                                                    de
                                                    la
                                                    carrera: </b> {{ informationStudent.startDateLic }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2" v-if="informationStudent.endDateLic">
                                                <b>Termino
                                                    de la
                                                    carrera: </b> {{ informationStudent.endDateLic }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Maestría -->
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row" v-show="informationStudent.schoolMa">
                                            <div class="text-justify m-2" v-if="informationStudent.schoolMa"><b>Escuela
                                                    de
                                                    Licenciatura: </b> {{ informationStudent.schoolMa }} </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.schoolMa">
                                            <div class="text-justify m-2" v-if="informationStudent.careerMa"><b>Nombre
                                                    de la
                                                    carrera: </b> {{ informationStudent.careerMa }} </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row" v-show="informationStudent.schoolMa">
                                            <div class="text-justify m-2" v-if="informationStudent.startDateMa">
                                                <b>Inicio
                                                    de la
                                                    carrera: </b> {{ informationStudent.startDateMa }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.schoolMa">
                                            <div class="text-justify m-2" v-if="informationStudent.endDateMa">
                                                <b>Termino de
                                                    la
                                                    carrera: </b> {{ informationStudent.endDateMa }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Doctorado -->
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row" v-show="informationStudent.schoolDoc">
                                            <div class="text-justify m-2" v-if="informationStudent.schoolDoc"><b>Escuela
                                                    de
                                                    Licenciatura: </b> {{ informationStudent.schoolDoc }} </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.schoolDoc">
                                            <div class="text-justify m-2" v-if="informationStudent.careerDoc"><b>Nombre
                                                    de
                                                    la
                                                    carrera: </b> {{ informationStudent.careerDoc }} </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: row;">
                                        <div class="row" v-show="informationStudent.schoolDoc">
                                            <div class="text-justify m-2" v-if="informationStudent.startDateDoc">
                                                <b>Inicio
                                                    de
                                                    la
                                                    carrera: </b> {{ informationStudent.startDateDoc }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="informationStudent.schoolDoc">
                                            <div class="text-justify m-2" v-if="informationStudent.endDateDoc">
                                                <b>Termino
                                                    de la
                                                    carrera: </b> {{ informationStudent.endDateDoc }}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <!-- Idiomas -->
                        <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                            tabindex="0">
                            <div class="container-postulante flex mt-3" style="padding: 2rem;">
                                <div class="row" v-for="(exp, i) in informationStudent.idiomas" :key="i + 1">
                                    <div class="col-4 text-justify m-2"> <b>Idioma {{ i + 1 }}</b> {{ exp.idioma }}
                                    </div>
                                    <div class="col-4 text-justify m-2"> <b>Nivel:</b> {{ exp.nivel }} </div>
                                </div>
                            </div>
                        </div>
                        <!-- Hobbies -->
                        <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                            tabindex="0">
                            <div class="container-postulante flex mt-3" style="padding: 2rem;">
                                <div class="row mb-2" v-for="(exp, i) in informationStudent.hobbies" :key="i + 1">
                                    <div class="col text-justify"> <b>Hobbie: </b> {{ exp.name }}</div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col text-justify" v-if="informationStudent.hobbieWrite1"> <b>Hobbie:</b>
                                        {{ informationStudent.hobbieWrite1 }}
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col text-justify" v-if="informationStudent.hobbieWrite2"> <b>Hobbie:</b>
                                        {{ informationStudent.hobbieWrite2 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Experiencia -->
                        <div class="tab-pane fade" id="experiencia-tab-pane" role="tabpanel"
                            aria-labelledby="experiencia-tab" tabindex="0">
                            <div class="container-postulante flex" style="padding: 2rem;">
                                <div v-for="(exp, i) in informationStudent.experiencia" :key="i + 1">
                                    <div v-if="exp.experienciaCompany"
                                        style="display: flex; flex-direction: row; justify-content: left; align-items: left; margin-top: 10px;">
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Nombre de la Empresa:</b>
                                                {{ exp.experienciaCompany }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Puesto:</b>
                                                {{ exp.experienciaPuesto }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="exp.experienciaCompany"
                                        style="display: flex; flex-direction: row; justify-content: left; align-items: left;">
                                        <div class="row">
                                            <div class="text-justify m-2"> <b>Salario: </b>
                                                ${{ exp.experienciaSalario }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2"><b>Fecha de Inicio:</b>
                                                {{ exp.experienciaFechaInicio }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div v-if="exp.experienciaFechaFin" class="text-justify m-2"><b>Fecha de
                                                    Termino:</b>
                                                {{ exp.experienciaFechaFin }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row mt-3 mb-2" style="margin-left: 10px;">
                                        <span class="text-center">Sin Experiencia Previa</span>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </main>
</template>

<script lang="js">
import { CompanyService } from "@/services";
import moment from "moment";
moment.locale("es");


export default {
    data: () => ({
        moment: moment,
        dataPostulate: [],
        dataPostulantetemp: [],
        informationPostulante: {},
        informationStudent: {},
        informationUser: {},
        searchInput2: "",
        id: "",
        show: "",
        idPostulante: "",
        statusPostulante: "",
        typeVacante: ""
    }),
    mounted() {
        this.id = this.$route.params.id;
        this.getPostulates(this.id);
        this.show = true;
    },
    methods: {
        // async postulateChange(status) {
        //     try {
        //         let idVacante = this.$route.params.id;
        //         await CompanyService.postulateChange({
        //             _id_vacante: idVacante,
        //             _id: this.idPostulante,
        //             status: status,
        //         });
        //         this.getPostulates(this.id);
        //         this.$forceUpdate();
        //     } catch (error) {
        //         this.$swal({
        //             position: "top-center",
        //             icon: "error",
        //             text: error.resp.data.message,
        //             showConfirmButton: false,
        //             timer: 4000,
        //         });
        //     }
        // },
        viewPostulantesFilter(item) {
            this.getPostulates(item.student._id);
        },
        async getPostulates(id) {
            try {
                let resp = await CompanyService.getPostulates({ id: id });
                let { data } = resp.data;
                resp.data.data.forEach((element, index) => {
                    if (index === 0) {
                        this.typeVacante = element.vacante.type;
                        console.log(this.typeVacante)
                    }
                });
                this.dataPostulate = data;
                this.dataPostulantetemp = data;
                //TODO: Muestra los registros que son diferente al estatus rechazado
                let filtro = this.dataPostulate.filter(estudiante => {
                    return estudiante.status != "no apto";
                });
                this.dataPostulate = filtro;

            } catch (e) {
                console.log(e.message);
            }
        },
        async viewInfo(item) {
            item.view = !item.view;
        },
        //TODO: Filtrado O Busqueda Por Postulante
        filterP(postulante) {
            if (this.searchInput2 != "") {
                return postulante.student.name.toLowerCase().match(this.searchInput2.toLowerCase()) || postulante.student.lastname.toLowerCase().match(this.searchInput2.toLowerCase()) || postulante.student.secondlastname.toLowerCase().match(this.searchInput2.toLowerCase());
            }
            else {
                return postulante;
            }
        },
        //TODO:Ordenar Postulantes Por Edad
        sortEdad(postulante) {
            postulante.sort((a, b) => {
                if (a.student.date == b.student.date) {
                    return 0;
                }
                if (a.student.date > b.student.date) {
                    return -1;
                }
                return 1;
            });
            return postulante;
        },
        //TODO: Ordenar por Salario
        sortSalario(postulante) {
            postulante.sort((a, b) => {
                if (parseInt(a.student.salarioAproximado) == parseInt(b.student.salarioAproximado)) {
                    return 0;
                }
                if (parseInt(a.student.salarioAproximado) < parseInt(b.student.salarioAproximado)) {
                    return -1;
                }
                return 1;
            });
            return postulante;
        },
        sortStatusApto() {
            let vacantesfilter = this.dataPostulantetemp.filter(vac => {
                return vac.status === "apto";
            });
            if (vacantesfilter.length != 0) {
                return this.dataPostulate = vacantesfilter;
            } else {
                this.$swal({
                    position: "top-right",
                    toast: true,
                    icon: "info",
                    title: "No se encontraron datos a mostrar",
                    showConfirmButton: false,
                    timer: 2500,
                });
                return this.dataPostulate = this.dataPostulantetemp;
            }
        },
        sortStatusNoApto() {
            let vacantesfilter = this.dataPostulantetemp.filter(vac => {
                return vac.status === "no apto";
            });
            if (vacantesfilter.length != 0) {
                return this.dataPostulate = vacantesfilter;
            } else {
                this.$swal({
                    position: "top-right",
                    toast: true,
                    icon: "info",
                    title: "No se encontraron datos a mostrar",
                    showConfirmButton: false,
                    timer: 2500,
                });
                return this.dataPostulate = this.dataPostulantetemp;
            }
        },
        sortStatusConsiderar() {
            let vacantesfilter = this.dataPostulantetemp.filter(vac => {
                return vac.status === "a considerar";
            });
            if (vacantesfilter.length != 0) {
                return this.dataPostulate = vacantesfilter;
            } else {
                this.$swal({
                    position: "top-right",
                    toast: true,
                    icon: "info",
                    title: "No se encontraron datos a mostrar",
                    showConfirmButton: false,
                    timer: 2500,
                });
                return this.dataPostulate = this.dataPostulantetemp;
            }
        },
        sortStatus() {
            return this.dataPostulate = this.dataPostulantetemp;
        },
        //TODO: Calcular Edad De Postulantes
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },
        viewPostulates(item) {
            this.informationPostulante = item;
            this.show = Object.entries(this.informationPostulante).length === 0;
            let { student, user, _id, status } = this.informationPostulante;
            this.idPostulante = _id;
            this.informationStudent = student;
            this.informationUser = user;
            this.statusPostulante = status;
        },
        openLinkedin(url) {
            let word = "https://"
            url.includes(word) ? window.open(url) : window.open("https://" + url);
        }
    },
};
</script>

<style land="scss" scoped>
@import "../styles/informacionpostulantes.scss";
</style>